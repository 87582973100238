import styled, { css } from "styled-components"

const Button = styled.button`
  background: #41b878;
  font-family: sofia-pro, sans-serif;
  border: none;
  border-radius: 60px;
  color: #f4f7de;
  font-size: 1rem;
  padding: 1rem 1.5rem;
  cursor: pointer;

  ${props =>
    props.secondary &&
    css`
      background: #e9ed9a;
      color: #0f4852;
    `}
`

export default Button
