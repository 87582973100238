import React, { Fragment } from "react"
import styled from "styled-components"

const Features = ({ features, background }) => (
  <FeaturesWrapper>
    {features.map((feature, i) => (
      <Fragment key={i}>
        <Feature>{feature}</Feature>
        <Line />
      </Fragment>
    ))}
  </FeaturesWrapper>
)

const Line = styled.div`
  background: #f4f7de;
  opacity: 0.3;
  width: 100%;
  min-width: 1px;
  min-height: 1px;
  flex: 0;
`

const Feature = styled.div`
  /* background: #0f4852; */
  padding: 50px 30px;
  /* border: 1px solid red; */
  width: ${1 / 3};
  flex: 1;
`

const FeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #0f4852;
  border-radius: 20px;

  @media (min-width: 1060px) {
    flex-direction: row;
  }
  /* background: ${props => props.background}; */
`

export default Features
