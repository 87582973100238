import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import { paths } from "../constants"

import Button from "../components/button"
import Layout from "../components/layout"
import Module from "../components/module"
import Stack from "../components/stack"
import Text from "../components/text"
import Heading from "../components/heading"
import Hero from "../components/hero"
import colors from "../colors"
import Features from "../components/features"
import TextContainer from "../components/textContainer"
import TalkToUs from "../components/talkToUs"
import Container from "../components/container"
// import { getImage } from "gatsby-plugin-image"

// const IMAGE_QUERY = graphql`
//   {
//     file(name: { eq: "hero-home" }) {
//       childImageSharp {
//         gatsbyImageData
//       }
//     }
//   }
// `

const QUERY = graphql`
  {
    sanityHeroImage(name: { eq: "home" }) {
      id
      image {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`

// markup
const IndexPage = () => {
  // console.log(data)
  const data = useStaticQuery(QUERY)
  // const image = getImage(data.file)

  return (
    <Layout>
      <Hero
        title="Our care is focused on you."
        image={data.sanityHeroImage.image.asset.gatsbyImageData}
      >
        <Heading as="h1" inverted>
          Our care is focused on you.
        </Heading>
      </Hero>
      <Module>
        <Container>
          <Stack gap={80 / 16}>
            <TextContainer>
              <Stack>
                <Heading as="h2">
                  At QMedical, our patients are everything.
                </Heading>
                <Text large>
                  When you attend our practice, you can expect personal,
                  thorough and efficient care. The best value. Every
                  appointment.
                </Text>
                <div>
                  <Link to={paths.contact}>
                    <Button
                      large
                      color={colors.cream}
                      background={colors.green}
                    >
                      Get in touch
                    </Button>
                  </Link>
                </div>
              </Stack>
            </TextContainer>
            <Features
              background={colors.teal}
              features={[
                <Stack gap={20 / 16}>
                  <Heading as="h3" inverted>
                    About Us
                  </Heading>
                  <Text inverted>
                    We’re a committed, compassionate general practice delivering
                    premium care for our patients. For our exceptional doctors,
                    your experience is the first priority. Our practice is
                    co-located by pathology and radiology clinics, so you can
                    seek holistic treatment all in one place.
                  </Text>
                </Stack>,
                <Stack gap={20 / 16}>
                  <Heading as="h3" inverted>
                    Services
                  </Heading>
                  <Text inverted>
                    As a general practice, we provide a wide range of services,
                    including vaccinations, minor procedures and skin checks. We
                    also sub-specialise in occupational health services, so we
                    offer comprehensive treatment for workplace health and
                    safety, WorkCover and other work-related matters.
                  </Text>
                </Stack>,
                <Stack gap={20 / 16}>
                  <Heading as="h3" inverted>
                    Work with us
                  </Heading>
                  <Text inverted>
                    We can promise excellent care to our patients because our
                    doctors deliver dedicated and genuine care for each patient.
                    And there is always room for more of us—so if that sounds
                    like you, we’d love to meet you.
                  </Text>
                  <div>
                    <Link to={paths.careers}>
                      <Button color={colors.cream} background={colors.green}>
                        View current opportunities
                      </Button>
                    </Link>
                  </div>
                </Stack>,
              ]}
            />
          </Stack>
        </Container>
      </Module>
      <TalkToUs>
        If you have any questions or would like to join our practice—as a
        patient or staff member—we’d love to hear from you.
      </TalkToUs>
    </Layout>
  )
}

export default IndexPage
