import React from "react"

import Button from "./button"
import Heading from "./heading"
import Module from "./module"
import Stack from "./stack"
import { paths } from "../constants"
import { Link } from "gatsby"
import Text from "./text"
import Container from "./container"
import TextContainer from "./textContainer"

const TalkToUs = ({ children, noButton }) => {
  return (
    <Module background="#41B878">
      <Container>
        <TextContainer>
          <Stack>
            <Heading inverted as="h2">
              Talk to Us
            </Heading>
            <Text large inverted>
              {children}
            </Text>
            {noButton || (
              <Link to={paths.contact}>
                <Button secondary>Get in touch</Button>
              </Link>
            )}
          </Stack>
        </TextContainer>
      </Container>
    </Module>
  )
}

export default TalkToUs
